<template>
  <div class="app-container merch-card">
    <!-- 搜索列 -->
    <div style="display: flex; justify-content: space-between">
      <el-form ref="searchParams" :model="searchParams" :inline="true">
        <el-form-item class="minItem">
          <el-date-picker
            v-model="dateTime"
            type="daterange"
            range-separator="至"
            start-placeholder="支付开始日期"
            end-placeholder="支付结束日期"
            unlink-panels
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="searchParams.orderGroupId"
            placeholder="请输入平台单据编号"
            clearable
            @keyup.enter.native="handleCurrentChange"
          />
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="searchParams.paySerial"
            placeholder="请输入交易流水号"
            clearable
            @keyup.enter.native="handleCurrentChange"
          />
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="searchParams.saleName"
            placeholder="请输入账单单位"
            clearable
            @keyup.enter.native="handleCurrentChange"
          />
        </el-form-item>
        <el-form-item>
          <el-select
            clearable
            v-model="searchParams.tradeType"
            placeholder="请选择交易类型"
          >
            <el-option
              v-for="item in dealType"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            clearable
            v-model="searchParams.paymentChannel"
            placeholder="请选择支付渠道"
          >
            <el-option
              v-for="item in payResult"
              :key="item.type"
              :label="item.name"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div style="width: 18%;text-align: right; margin-top: 1%">
        <el-button
          type="primary"
          @click="handleCurrentChange"
          style="width: 80px"
          >查 询</el-button
        >
        <el-button @click="handleReset" style="width: 80px" plain
          >重 置</el-button
        >
        <el-button type="primary" @click="handleExport">导 出</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <div
      class="merch-table scorr-roll"
      :style="`max-height: ` + mainHright + `px`"
    >
      <div>
        <el-table
          :data="searchListData"
          v-loading="tableLoading"
          border
          style="width: 100%"
        >
          <el-table-column
            prop="id"
            label="序号"
            align="center"
            width="60"
          ></el-table-column>
          <el-table-column
            prop="serialNumber"
            label="交易流水号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="receiptType"
            label="单据"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="orderGroupId"
            label="平台单据编号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="tradeType"
            label="交易类型"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="paymentChannel"
            label="支付渠道"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="amount"
            label="平台交易金额"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="saleName"
            label="账单单位"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="payTime"
            label="支付时间"
            align="center"
          ></el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <div class="merch-page">
      <el-pagination
        background
        @current-change="searchBusinessList"
        layout="total, prev, pager, next, jumper"
        :total="total"
        :page-size="10"
        :current-page="current_page"
      >
      </el-pagination>
    </div>
  </div>
</template>
  
<script>
import { downLoadZip } from "@/utils/zipdownload";
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const merchantCenterList = createNamespacedHelpers("merchantCenterList");
const billList = createNamespacedHelpers("billList");
export default {
  data() {
    return {
      tableLoading: false,
      total: 0,
      current_page: null,
      searchParams: {
        page: 1,
        perPage: 10,
        startTime: "",
        endTime: "",
        orderGroupId: "",
        paySerial: "",
        saleName: "", //
        paymentChannel: "",
        tradeType: "",
      },
      dateTime: [],
      payResult: [
        {
          type: 1,
          name: "中金支付",
        },
      ],
      dealType: [
        {
          code: 1,
          name: "销售订单",
        },
        {
          code: -1,
          name: "退款订单",
        },
        {
          code: 2,
          name: "还款订单",
        },
      ],
      searchListData: [],
    };
  },
  computed: {
    ...commonIndex.mapState(["mainHright"]),
    ...merchantCenterList.mapState(["page"]),
  },
  created() {
    this.getSearchList();
  },
  methods: {
    ...billList.mapActions(["orderStatementList"]),
    handleReset() {
      this.dateTime = []
      Object.assign(this.searchParams, this.$options.data().searchParams);
      this.getSearchList();
    },
    handleExport() {
      let params = JSON.parse(JSON.stringify(this.searchParams));
      delete params.page;
      delete params.perPage;
      downLoadZip(
        `/orderStatement/orderStatementListExport`,
        "xlsx",
        "对账单列表",
        params,
        "php",
        "get"
      );
    },
    async getSearchList() {
      this.tableLoading = true;
      if (this.dateTime && this.dateTime.length > 0) {
        this.searchParams.startTime = this.dateTime[0]
        this.searchParams.endTime = this.dateTime[1]
      } else {
        this.searchParams.startTime = "";
        this.searchParams.endTime = "";
      }
      let { data } = await this.orderStatementList(this.searchParams);
      this.tableLoading = false;
      this.searchListData = data.data;
      this.current_page = data.currentPage;
      this.total = data.total;
    },
    //当前页
    searchBusinessList(e) {
      if (e != undefined) {
        this.searchParams.page = e;
      }
      this.getSearchList();
    },
    //搜索
    handleCurrentChange() {
      this.searchParams.page = 1;
      this.getSearchList();
    },
  },
};
</script>
<style lang='scss' scoped>
.el-date-editor .el-range-separator {
  padding: 0 5px;
  line-height: 32px;
  width: 20%;
  color: #303133;
}
</style>
  <style lang="scss" scoped>
.app-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  .merch-table {
    margin-top: 1%;
    width: 100%;
    height: 77%;
  }
  .userBtn {
    display: flex;
    justify-content: center;
    div {
      margin-left: 5px;
      color: #1abca9;
      cursor: pointer;
    }
  }
}
::v-deep .el-input--small .el-input__inner {
  height: 40px;
  line-height: 40px;
}
::v-deep .el-form--inline .el-form-item {
  margin-right: 2%;
  margin-top: 1%;
}
</style>
  